import React from 'react'
import { Router, useLocation } from "@reach/router"
import { DocumentContextProvider } from './common/DocumentContextProvider'
import Auth from './auth/components/Auth'
import withContextProvider from './common/withContextProvider'
import withSuspense from './common/withSuspense'
import withDefaultLayout from './common/withDefaultLayout'
import withErrorBoundary from './common/withErrorBoundary'
import { useAuth } from './auth/AuthContextProvider'

//Dashboard
const Dashboard = React.lazy(() => import('./common/views/dashboard/Dashboard'))
const DashboardWithErrorBoundary = withErrorBoundary(Dashboard)
//Auth
const Login = React.lazy(() => import('./auth/components/Login'))
const Logout = React.lazy(() => import('./auth/components/Logout'))
//Customer
const CustomerList = React.lazy(() => import('./customer/components/CustomerList'))
const CustomerListWithProvider = withErrorBoundary(withContextProvider(CustomerList, DocumentContextProvider))
const TaggedCustomersMap = React.lazy(() => import('./customer/components/TaggedCustomersMap'))
const TaggedCustomersMaptWithProvider = withErrorBoundary(withContextProvider(TaggedCustomersMap, DocumentContextProvider))
//Receivables
const ReceivablesList = React.lazy(() => import('./receivable/components/ReceivablesList'))
const ReceivablesListWithProvider = withErrorBoundary(withContextProvider(ReceivablesList, DocumentContextProvider))

//Aging
const AgingReport = React.lazy(() => import('./receivable/components/AgingReport'))
const AgingReportWithProvider = withErrorBoundary(withContextProvider(AgingReport, DocumentContextProvider))

//Billing
const BillingList = React.lazy(() =>
  import('./billing/components/BillingList'),
)
const BillingListWithProvider = withErrorBoundary(withContextProvider(BillingList, DocumentContextProvider))
//Payment
const PaymentList = React.lazy(() =>
  import('./payment/components/PaymentList'),
)
const PaymentListWithProvider = withErrorBoundary(withContextProvider(PaymentList, DocumentContextProvider))

//Adjustment
const AdjustmentList = React.lazy(() =>
  import('./adjustment/components/AdjustmentList'),
)
const AdjustmentListWithProvider = withErrorBoundary(withContextProvider(AdjustmentList, DocumentContextProvider))

//Service Issue
const ServiceIssueList = React.lazy(() =>
  import('./service-issue/components/ServiceIssueList'),
)
const ServiceIssueListWithProvider = withErrorBoundary(withContextProvider(ServiceIssueList, DocumentContextProvider))

const PrivateRoute = props => {  
  const [ state, {isAuth, getCookie} ] = useAuth()
  const location = useLocation()
  let { as: Comp, ...restOfTheProps } = props;
  return isAuth() ? <Comp {...restOfTheProps } /> : <Auth {...props} path="/auth" />
}

const Routes = props => {
  return (
    <Router>
      <PrivateRoute as={withDefaultLayout(DashboardWithErrorBoundary)} {...props} path="/" />
      <PrivateRoute as={withDefaultLayout(Dashboard)} {...props} path="/home" />
      <PrivateRoute as={withDefaultLayout(Dashboard)} {...props} path="/dashboard" />
      <PrivateRoute as={withDefaultLayout(CustomerListWithProvider)} {...props} path="/customers" />
      <PrivateRoute as={withDefaultLayout(TaggedCustomersMaptWithProvider)} {...props} path="/tagged-customers" />
      <PrivateRoute as={withDefaultLayout(ReceivablesListWithProvider)} {...props} path="/receivables" />
      <PrivateRoute as={withDefaultLayout(AgingReportWithProvider)} {...props} path="/aging-summary" />
      <PrivateRoute as={withDefaultLayout(BillingListWithProvider)} {...props} path="/billings" />
      <PrivateRoute as={withDefaultLayout(PaymentListWithProvider)} {...props} path="/payments" />
      <PrivateRoute as={withDefaultLayout(AdjustmentListWithProvider)} {...props} path="/adjustments" />
      <PrivateRoute as={withDefaultLayout(ServiceIssueListWithProvider)} {...props} path="/service-issues" />
      <Login {...props} path="/login" />
      <Logout {...props} path="/logout" />
      <Auth {...props} path="/auth" />
    </Router>
  )
}

export default withSuspense(Routes)
