export const GET = 'GET'
export const GET_POST_PATCH = 'GET_POST_PATCH'
export const PUT_PATCH = 'PUT_PATCH'

const RESOURCES_ACCESSIBLE = {
  'supervisor': {
    'REVENUE_WIDGETS': [GET],
    'PAYMENTS_LIST': [GET],
    'PAYMENTS': [GET, GET_POST_PATCH],
    'PAYMENTS_APPROVAL': [PUT_PATCH],
    'ADJUSTMENTS_LIST': [GET],
    'ADJUSTMENTS': [GET, GET_POST_PATCH],
    'ADJUSTMENTS_APPROVAL': [PUT_PATCH],
  },
  'cashier': {
    'REVENUE_WIDGETS': [GET],
    'PAYMENTS_LIST': [GET],
    'PAYMENTS': [GET, GET_POST_PATCH],
    'PAYMENTS_APPROVAL': [PUT_PATCH],
    'ADJUSTMENTS_LIST': [GET],
    'ADJUSTMENTS': [GET, GET_POST_PATCH],
    'ADJUSTMENTS_APPROVAL': [PUT_PATCH],
  },
  'marketing': {
    'REVENUE_WIDGETS': [GET],
    'PAYMENTS_LIST': [GET],
    'PAYMENTS': [GET],
    'ADJUSTMENTS_LIST': [GET],
    'ADJUSTMENTS': [GET],
  }
}

export const isAccessible = (resource, user, action) => {

  console.log('isAccessible', user, resource, action)
  return ['super-admin','admin'].includes(user?.role) ||
    [].concat(RESOURCES_ACCESSIBLE[user?.role || 'guest']?.[resource]).includes(action)

}
